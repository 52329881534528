import * as React from "react";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import { Chip, Icon } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { customer_tiers } from "src/utils/common";
import { useState } from "react";
import { loadingUsers, modifyCustomerTier } from "src/actions/users";

export default function UserTierMenu({
  tier = "",
  onSuccess = function () {},
  userId = null,
  NonClickable,
}) {
  const _UserInfo = useSelector((state) => state.users.userInfo);

  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const dispatch = useDispatch();

  function updateOrderStatus(e) {
    dispatch(loadingUsers());
    dispatch(modifyCustomerTier(userId, { customerTier: e.target.id })).then(
      (res) => {
        dispatch(loadingUsers());
        onSuccess("User tier changed successfully");
      }
    );
  }

  const getStatusIcon = (tier) => {
    const colorMap = {
      0: "#4B0082",
      1: "#E6B800",
      2: "#4682B4",
      3: "#006400",
      4: "#008080",
      5: "#d32f2f",
      6: "#FF4500",
      7: "#696969",
      default: "#BDBDBD",
    };

    const color = colorMap[tier] || colorMap.default;

    return (
      <Chip
        size="small"
        label={
          customer_tiers.filter((x) => x.id === tier)[0]?.name ?? "Normal User"
        }
        style={{ backgroundColor: color, color: "#FFF" }}
        clickable={NonClickable ? false : true}
        onClick={NonClickable ? null : handleClick}
      />
    );
  };

  return (
    <React.Fragment>
      {getStatusIcon(tier)}
      <Menu
        anchorEl={anchorEl}
        open={
          _UserInfo.roles.includes("SystemAdmin")
            ? open
            : false ||
              (tier === null &&
                _UserInfo.roleOEndpoint
                  .map((data) => data.Code)
                  .includes("31-21"))
            ? open
            : false
        }
        onClose={handleClose}
        onClick={handleClose}
        PaperProps={{
          elevation: 0,
          sx: {
            overflow: "visible",
            filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
            mt: 1.5,
            "& .MuiAvatar-root": {
              width: 32,
              height: 32,
              ml: -0.5,
              mr: 1,
            },
            "&:before": {
              content: '""',
              display: "block",
              position: "absolute",
              top: 0,
              right: 14,
              width: 10,
              height: 10,
              bgcolor: "background.paper",
              transform: "translateY(-50%) rotate(45deg)",
              zIndex: 0,
            },
          },
        }}
        transformOrigin={{ horizontal: "right", vertical: "top" }}
        anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
      >
        {customer_tiers.map((tier) => (
          <MenuItem key={tier.id} id={tier.id} onClick={updateOrderStatus}>
            <ListItemIcon>
              <Icon>{tier.icon}</Icon>
            </ListItemIcon>
            {tier.name}
          </MenuItem>
        ))}
      </Menu>
    </React.Fragment>
  );
}
