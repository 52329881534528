import React, { useState } from "react";
import PerfectScrollbar from "react-perfect-scrollbar";
import EditIcon from "@mui/icons-material/Edit";
import { DeleteForever } from "@mui/icons-material";
import { Box } from "@mui/system";
import {
  Button,
  Card,
  Divider,
  IconButton,
  Tooltip,
  Typography,
  Stack,
  LinearProgress,
  Grid,
  Chip,
  Autocomplete,
  TextField,
} from "@mui/material";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import moment from "moment";
import {
  clearGlobalCart,
  deleteGlobalCartItem,
  getCartById,
  openCartDialog,
  updateCart,
  updateProhibitedCartItems,
} from "src/actions/cart";
import { useDispatch, useSelector } from "react-redux";
import { completeReview } from "src/actions/cart";
import { Icon } from "@mui/material";
import { RxDimensions } from "react-icons/rx";
import { FaWeightHanging } from "react-icons/fa";
import { MdDateRange, MdOutlineRemoveShoppingCart } from "react-icons/md";
import { CgDanger } from "react-icons/cg";
import { MdProductionQuantityLimits } from "react-icons/md";
import { useEffect } from "react";
import { VENDORS_LOGO } from "src/utils/common";
import CustomSpinner from "src/components/CustomSpinner";
import { CustomDialog } from "src/components/CustomDialog";
import CustomEmpty from "src/components/CustomEmpty";
import CustomText from "src/components/CustomText";
import { DataGrid } from "@mui/x-data-grid";
import { HiOutlineMagnifyingGlassCircle } from "react-icons/hi2";
import RolePermission from "src/components/RolePermission";
import { getAllListProhibitedItems } from "src/actions/prohibitedItems";
import ProhibitedItemsReasonsMenu from "src/pages/cross/cart/prohibitedItemsMenu/Manu";
import UserTierMenu from "src/pages/cross/Users/userTierMenu";
import AddIcon from "@mui/icons-material/Add";
import CartExternalItemForm from "src/pages/cross/cart/form/Form";

export default function CartTable() {
  const cart = useSelector((state) => state.cart.data);
  const reload = useSelector((state) => state.cart.reload);
  const prohibitedItems = useSelector(
    (state) => state.prohibitedItems.prohibitedItemsDataList
  );
  const _ExternalItemDialog = useSelector((state) => state.cart.openDialog);

  const cartId = window.location.pathname.replace("/app/cart/", "");

  const [refresh, setRefresh] = useState(false);
  const [editLoading, setEditLoading] = useState(false);
  const [openEditDialog, setOpenEditDialog] = useState(false);
  const [confirmClearCartDialog, setConfirmClearCartDialog] = useState(false);
  const [confirmDeleteItemDialog, setConfirmDeleteItemDialog] = useState({
    open: false,
    selectedItem: null,
  });
  const [prohibitedItemsDialog, setProhibitedItemsDialog] = useState({
    open: false,
    selectedItem: null,
  });
  const [selectedReasonId, setSelectedReasonId] = useState(null);

  useEffect(() => {
    getUserCartById(cartId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [refresh]);

  useEffect(() => {
    dispatch(getAllListProhibitedItems());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  function completeReviewByCartId() {
    dispatch(completeReview(cartId))
      .then((res) => {
        setRefresh(!refresh);
      })
      .catch((err) => {});
  }

  function handleClearCart() {
    dispatch(clearGlobalCart(cartId))
      .then((res) => {
        if (res?.message === "Success") {
          setRefresh(!refresh);
          setConfirmClearCartDialog(false);
        }
      })
      .catch((err) => {});
  }

  function handleDeleteCartItem(itemId) {
    dispatch(deleteGlobalCartItem(itemId, cartId))
      .then((res) => {
        if (res?.message === "Success") {
          setRefresh(!refresh);
          setConfirmDeleteItemDialog({
            open: false,
            selectedItem: null,
          });
        }
      })
      .catch((err) => {});
  }

  function handleProhibitedCartItem(cartId, itemId, selectedReasonId) {
    dispatch(
      updateProhibitedCartItems({
        cartId: cartId,
        itemId: itemId,
        prohibitedItemReasonId: selectedReasonId,
      })
    )
      .then((res) => {
        if (res?.message === "Success") {
          setRefresh(!refresh);
          setProhibitedItemsDialog({
            open: false,
            selectedItem: null,
          });
        }
      })
      .catch((err) => {});
  }

  function getUserCartById(id) {
    dispatch(getCartById(id));
  }

  const [selectedItem, setSelectedItem] = useState({
    itemId: null,
    weight: "",
    weightUnit: "",
    dimensions: "",
    dimensionsUnit: "",
    dangerousGoods: "",
    dangerousGoodsUnit: "",
    shippingCost: 0,
    deliveryDate: "",
    quantity: "",
    color: "",
    size: "",
  });

  const dispatch = useDispatch();

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    const newValue = value.replace(/\u200E/g, "");
    setSelectedItem({ ...selectedItem, [name]: newValue });
  };

  function clearTexts() {
    setSelectedItem({
      ...selectedItem,
      weight: "",
      weightUnit: "",
      dimensions: "",
      dimensionsUnit: "",
      dangerousGoods: "",
      dangerousGoodsUnit: "",
      deliveryDate: null,
      shippingCost: 0,
      quantity: "",
    });
  }

  const Vendor = ({ params }) => {
    return (
      <Stack>
        <img
          alt="vendor logo"
          src={
            params.vendorLogo
              ? params.vendorLogo
              : VENDORS_LOGO.filter((vendor) =>
                  vendor.name == params.vendorName ? vendor.path : null
                )[0]?.path
          }
          width={95}
        />
        {params.shippingOptions != null ? (
          <Chip
            size="small"
            label={params.shippingOptions?.nameEn}
            color={
              params.shippingOptions?.nameEn === "Sea"
                ? "info"
                : params.shippingOptions?.nameEn === "Express"
                ? "warning"
                : "success"
            }
          />
        ) : null}
      </Stack>
    );
  };

  const Item = ({ params }) => {
    return (
      <Box>
        <Box sx={{ display: "flex" }}>
          <Box
            component="img"
            sx={{
              height: 130,
              maxWidth: 150,
            }}
            alt="itemImage"
            src={params.imageUrl}
          />
          <Box sx={{ marginLeft: "10px", width: "220px" }}>
            <Tooltip title={params.name}>
              <a
                rel="noreferrer"
                href={
                  params.productUrl.includes("shein")
                    ? params.productUrl.replace(/(https:\/\/\s*)m/g, "$1ar")
                    : params.productUrl
                }
                target="_blank"
              >
                <Typography variant="body1" noWrap>
                  {params.name}
                </Typography>
              </a>
            </Tooltip>
            <Typography>Price: {params.price} $</Typography>
            <Typography>
              <Tooltip title={params.size ? `size: ${params.size}` : ""}>
                <Typography variant="body1" noWrap>
                  {params.size ? `size: ${params.size}` : ""}
                </Typography>
              </Tooltip>
            </Typography>
            <Typography>
              <Tooltip title={params.color ? `color: ${params.color}` : ""}>
                <Typography variant="body1" noWrap>
                  {params.color ? `color: ${params.color}` : ""}
                </Typography>
              </Tooltip>
            </Typography>
            <Typography>
              <Tooltip title={params.style ? `style: ${params.style}` : ""}>
                <Typography variant="body1" noWrap>
                  {params.style ? `style: ${params.style}` : ""}
                </Typography>
              </Tooltip>
            </Typography>
          </Box>
        </Box>
        <ProhibitedItemsReasonsMenu
          id={params.id}
          status={params.prohibitedItemsReasonsDto?.nameEn}
          reasonNumber={params.prohibitedItemsReasonsDto?.id}
          cartId={cartId}
          onSuccess={() => {
            setRefresh(!refresh);
          }}
        />
      </Box>
    );
  };
  const EditableValues = ({ params }) => {
    return (
      <Box sx={{ width: "100%" }}>
        <Typography
          sx={{ margin: "5px", fontSize: 13 }}
          color={params.quantity > 1 ? "#ff9800" : ""}
        >
          <Tooltip title="Quantity">
            <IconButton size="small" sx={{ p: 0, m: 0 }}>
              <MdProductionQuantityLimits />
            </IconButton>
          </Tooltip>
          {` : ${params.quantity}`}
        </Typography>
        <Typography sx={{ margin: "10px 5px", fontSize: 13 }}>
          <Tooltip title="Dimensions">
            <IconButton size="small" sx={{ p: 0, m: 0 }}>
              <RxDimensions />
            </IconButton>
          </Tooltip>
          {` : ${params.dimensions ? params.dimensions : "N/A"}`}
        </Typography>
        <Typography sx={{ margin: "10px 5px", fontSize: 13 }}>
          <Tooltip title="Weight">
            <IconButton size="small" sx={{ p: 0, m: 0 }}>
              <FaWeightHanging />
            </IconButton>
          </Tooltip>
          {` : ${params.weight}`}
        </Typography>
        <Typography sx={{ margin: "10px 5px", fontSize: 13 }}>
          <Tooltip title="Delivery Date">
            <IconButton size="small" sx={{ p: 0, m: 0 }}>
              <MdDateRange />
            </IconButton>
          </Tooltip>
          {` : ${
            params.deliveryDate
              ? moment(params.deliveryDate).format("yyyy-MM-DD")
              : "N/A"
          }`}
        </Typography>
        <Typography sx={{ margin: "10px 5px", fontSize: 13 }}>
          <Tooltip title="Dangerous Goods">
            <IconButton size="small" sx={{ p: 0, m: 0 }}>
              <CgDanger />
            </IconButton>
          </Tooltip>
          {` : ${params.dangerousGoods}`}
        </Typography>
      </Box>
    );
  };

  function updateItem() {
    setEditLoading(true);
    selectedItem.itemId = selectedItem.id;
    dispatch(updateCart(selectedItem)).then((res) => {
      setOpenEditDialog(false);
      setRefresh(!refresh);
      setEditLoading(false);
    });
  }

  const columns = [
    {
      field: "#",
      headerName: "#",
      flex: 0.3,
      sortable: false,
      disableColumnMenu: true,
      renderCell: (index) => index.api.getRowIndex(index.row.id) + 1,
    },
    {
      field: "vendor",
      headerName: "Vendor",
      flex: 1,
      sortable: false,
      disableColumnMenu: true,
      renderCell: (params) => <Vendor params={params.row} />,
    },
    {
      field: "item",
      headerName: "Item Details",
      flex: 2,
      renderCell: (params) => <Item params={params.row} />,
    },
    {
      field: "EditableValues",
      headerName: "Editable Values",
      flex: 1,
      sortable: false,
      disableColumnMenu: true,
      renderCell: (params) => <EditableValues params={params.row} />,
    },
    {
      field: "localPrice",
      headerName: "Local Price",
      flex: 1,
      sortable: false,
      disableColumnMenu: true,
    },
    {
      field: "shippingCost",
      headerName: "Shipping Cost ($)",
      flex: 1,
      sortable: false,
      disableColumnMenu: true,
    },
    {
      field: "totalAmount",
      headerName: "Total Amount ($)",
      flex: 1,
      sortable: false,
      disableColumnMenu: true,
    },
    {
      field: "createdAt",
      headerName: "Date",
      flex: 1,
      sortable: false,
      disableColumnMenu: true,
      renderCell: (params) => (
        <Stack>
          <Typography variant="body">
            {moment(params.row.createdAt).format("YYYY-MM-DD")}
          </Typography>
          <Typography variant="caption">
            {moment(params.row.createdAt).format("hh:mm a")}
          </Typography>
        </Stack>
      ),
    },
    {
      field: "operations",
      headerName: "Operations",
      flex: 0.8,
      sortable: false,
      disableColumnMenu: true,
      renderCell: (params) => (
        <Stack>
          <RolePermission permission="06-03">
            <IconButton
              color="primary"
              aria-label="edit"
              onClick={(e) => {
                setOpenEditDialog(true);
                setSelectedItem(params.row);
              }}
            >
              <Tooltip
                title="Update"
                aria-label="edit"
                followCursor
                placement="top"
              >
                <EditIcon />
              </Tooltip>
            </IconButton>
          </RolePermission>
          <RolePermission permission="06-22">
            <IconButton
              color="primary"
              aria-label="delete"
              onClick={(e) => {
                setConfirmDeleteItemDialog({
                  open: true,
                  selectedItem: params.row,
                });
              }}
            >
              <Tooltip
                title="Delete"
                aria-label="edit"
                followCursor
                placement="top"
              >
                <DeleteForever color="error" />
              </Tooltip>
            </IconButton>
          </RolePermission>
        </Stack>
      ),
    },
  ];

  return (
    <Card>
      {_ExternalItemDialog && <CartExternalItemForm />}
      <CustomDialog
        isOpen={openEditDialog}
        handleClose={() => setOpenEditDialog(false)}
        title={"UPDATE ITEM"}
        maxWidth="md"
        isRequierdActions={true}
        isRequierdEdit
        onEdit={updateItem}
        onClear={clearTexts}
        loading={editLoading}
      >
        <form noValidate>
          <Grid container spacing={1}>
            <Grid item xs={12} md={4}>
              <CustomText
                label="Quantity"
                name="quantity"
                value={selectedItem.quantity}
                onChange={handleInputChange}
                fullWidth
              />
            </Grid>
            <Grid item xs={12} md={4}>
              <CustomText
                label="Dimensions"
                name="dimensions"
                value={selectedItem.dimensions}
                onChange={handleInputChange}
                fullWidth
              />
            </Grid>
            <Grid item xs={12} md={4}>
              <CustomText
                type="number"
                label="Weight"
                name="weight"
                value={selectedItem.weight}
                onChange={handleInputChange}
                fullWidth
              />
            </Grid>
            <Grid item xs={12} md={4}>
              <CustomText
                label="Dangerous Goods"
                name="dangerousGoods"
                value={selectedItem.dangerousGoods}
                onChange={handleInputChange}
                fullWidth
              />
            </Grid>
            <Grid item xs={12} md={4}>
              <CustomText
                label="Shipping Cost"
                name="shippingCost"
                value={selectedItem.shippingCost}
                onChange={handleInputChange}
                fullWidth
              />
            </Grid>
            <Grid item xs={12} md={4}>
              <LocalizationProvider dateAdapter={AdapterMoment}>
                <DatePicker
                  views={["year", "month", "day"]}
                  inputFormat="YYYY-MM-DD"
                  label="Delivery date"
                  name="deliveryDate"
                  value={selectedItem.deliveryDate}
                  onChange={(value) =>
                    setSelectedItem({ ...selectedItem, deliveryDate: value })
                  }
                  renderInput={(params) => (
                    <CustomText
                      fullWidth
                      size="small"
                      variant="outlined"
                      {...params}
                    />
                  )}
                />
              </LocalizationProvider>
            </Grid>
            <Grid item xs={12} md={4}>
              <CustomText
                label="Color"
                name="color"
                value={selectedItem.color}
                onChange={handleInputChange}
                fullWidth
              />
            </Grid>
            <Grid item xs={12} md={4}>
              <CustomText
                label="Size"
                name="size"
                value={selectedItem.size}
                onChange={handleInputChange}
                fullWidth
              />
            </Grid>
            <Grid item xs={12} md={4}>
              <CustomText
                label="Style"
                name="style"
                value={selectedItem.style}
                onChange={handleInputChange}
                fullWidth
              />
            </Grid>
          </Grid>
        </form>
      </CustomDialog>
      <CustomDialog
        isOpen={confirmClearCartDialog}
        handleClose={() => {
          setConfirmClearCartDialog(false);
        }}
        isActiveDelete
        isConfirm={true}
        isRequierdActions
        title="Confirmation"
        maxWidth="sm"
        onDelete={() => handleClearCart()}
        confirmTitle={`clear this cart`}
      ></CustomDialog>
      <CustomDialog
        isOpen={confirmDeleteItemDialog.open}
        handleClose={() => {
          setConfirmDeleteItemDialog({
            open: false,
            selectedItem: null,
          });
        }}
        isActiveDelete
        isRequierdActions
        title="Delete confirmation"
        maxWidth="sm"
        onDelete={() =>
          handleDeleteCartItem(confirmDeleteItemDialog.selectedItem?.id)
        }
        selectedObject={`${confirmDeleteItemDialog.selectedItem?.name}`}
      ></CustomDialog>
      <CustomDialog
        isOpen={prohibitedItemsDialog.open}
        handleClose={() => {
          setProhibitedItemsDialog({
            open: false,
            selectedItem: null,
          });
        }}
        title="Prohibited Item"
        maxWidth="sm"
        onDelete={() =>
          handleDeleteCartItem(prohibitedItemsDialog.selectedItem?.id)
        }
        selectedObject={`${prohibitedItemsDialog.selectedItem?.name}`}
      >
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Autocomplete
              size="small"
              options={prohibitedItems}
              getOptionLabel={(option) => `${option.nameAr} , ${option.nameEn}`}
              fullWidth
              renderInput={(params) => (
                <TextField {...params} label="Reasons" />
              )}
              onChange={(event, value) => {
                setSelectedReasonId(value ? value.id : null);
              }}
              value={
                prohibitedItems.filter((x) => x.id === selectedReasonId)[0] ??
                null
              }
            />
          </Grid>
          <Grid item xs={12}>
            <Button
              variant="contained"
              onClick={() => {
                handleProhibitedCartItem(
                  cartId,
                  prohibitedItemsDialog.selectedItem.id,
                  selectedReasonId
                );
              }}
            >
              Save
            </Button>
          </Grid>
        </Grid>
      </CustomDialog>
      {!cart.items ? (
        <Box
          sx={{
            height: { md: "calc(100vh - 175px)", xs: 1000 },
          }}
          display="flex"
          justifyContent="center"
          alignItems="center"
        >
          <CustomSpinner />
        </Box>
      ) : (
        <Box
          sx={{
            height: { md: "calc(100vh - 175px)", xs: 1000 },
            overflow: "auto",
          }}
        >
          <Grid container spacing={2}>
            <Grid item xs={12} md={9.5}>
              <PerfectScrollbar>
                <Box sx={{ height: { md: "calc(100vh - 175px)", xs: 1000 } }}>
                  <DataGrid
                    sx={{
                      ".highlight": {
                        bgcolor: "#fcb6b6",
                        "&:hover": {
                          bgcolor: "#faa2a2",
                        },
                      },
                    }}
                    getRowClassName={(params) => {
                      return params.row.prohibitedItemsReasonsDto
                        ? "highlight"
                        : "";
                    }}
                    loading={reload}
                    rows={cart.items ?? []}
                    columns={columns}
                    rowHeight={200}
                    disableSelectionOnClick
                    pagination
                    paginationMode="client"
                    components={{
                      LoadingOverlay: LinearProgress,
                      NoRowsOverlay: CustomEmpty,
                    }}
                  />
                </Box>
              </PerfectScrollbar>
            </Grid>
            <Grid item xs={12} md={2.5}>
              <Box mr={2}>
                <Stack spacing={1} my={2}>
                  <RolePermission permission="17-03">
                    <Button
                      variant="contained"
                      color="primary"
                      size="medium"
                      startIcon={<AddIcon />}
                      onClick={() => dispatch(openCartDialog())}
                    >
                      ADD EXTERNAL ITEM
                    </Button>
                  </RolePermission>
                  <RolePermission permission="06-06">
                    <Button
                      color="warning"
                      variant="contained"
                      fullWidth
                      startIcon={<HiOutlineMagnifyingGlassCircle />}
                      onClick={() => completeReviewByCartId()}
                    >
                      Complete Review
                    </Button>
                  </RolePermission>
                  <RolePermission permission="06-21">
                    <Button
                      color="error"
                      variant="contained"
                      fullWidth
                      startIcon={<MdOutlineRemoveShoppingCart />}
                      onClick={() => setConfirmClearCartDialog(true)}
                    >
                      Clear Cart
                    </Button>
                  </RolePermission>
                </Stack>
                <Stack spacing={2}>
                  <Stack spacing={2}>
                    <Stack>
                      <Stack
                        direction="row"
                        spacing={1}
                        justifyContent="space-between"
                      >
                        <Typography variant="h4">Total amount:</Typography>
                        <Typography variant="h4">
                          {cart.totalAmount ?? 0} $
                        </Typography>
                      </Stack>
                      <Stack
                        justifyContent="space-between"
                        direction="row"
                        spacing={1}
                      >
                        <Typography variant="h5">Subtotal:</Typography>
                        <Typography variant="h5">
                          {cart.subTotal ?? 0} $
                        </Typography>
                      </Stack>
                      <Stack
                        justifyContent="space-between"
                        direction="row"
                        spacing={1}
                      >
                        <Typography variant="h5">IQ customs:</Typography>
                        <Typography variant="h5">
                          {cart.totalIQCustoms ?? 0} $
                        </Typography>
                      </Stack>
                      <Stack
                        justifyContent="space-between"
                        direction="row"
                        spacing={1}
                      >
                        <Typography variant="h5">Orderii Fee:</Typography>
                        <Typography variant="h5">
                          {cart.orderiiFee ?? 0} $
                        </Typography>
                      </Stack>
                      <Stack
                        justifyContent="space-between"
                        direction="row"
                        spacing={1}
                      >
                        <Typography variant="h5">Insurance:</Typography>
                        <Stack direction="row" spacing={1}>
                          {cart.isInsured ? (
                            <>
                              <Icon color="success">check_circle</Icon>
                              <Typography color="success">
                                {cart.insuranceFee} $
                              </Typography>
                            </>
                          ) : (
                            <Icon color="error">cancel</Icon>
                          )}
                        </Stack>
                      </Stack>
                      {cart.promoCode && (
                        <Stack
                          justifyContent="space-between"
                          direction="row"
                          spacing={1}
                        >
                          <Typography variant="h5">Promo Code:</Typography>
                          <Typography variant="h5">
                            {cart.promoCode ?? ""}
                          </Typography>
                        </Stack>
                      )}
                      {cart.referralDiscount && (
                        <Stack
                          justifyContent="space-between"
                          direction="row"
                          spacing={1}
                        >
                          <Typography variant="h5">Referral Code:</Typography>
                          <Typography variant="h5">
                            {cart.referralCode}
                          </Typography>
                          <Icon color="success">check_circle</Icon>
                        </Stack>
                      )}
                      <Stack
                        justifyContent="space-between"
                        direction="row"
                        spacing={1}
                      >
                        <Typography color="error" variant="h5">
                          Total Discount:
                        </Typography>
                        <Typography color="error" variant="h5">
                          {cart.totalDiscount} $
                        </Typography>
                      </Stack>
                      {cart.totalCashback ? (
                        <Stack
                          justifyContent="space-between"
                          direction="row"
                          spacing={1}
                        >
                          <Typography variant="h5">Total CashBack:</Typography>
                          <Typography variant="h5">
                            {cart.totalCashback} $
                          </Typography>
                        </Stack>
                      ) : null}
                      <Stack
                        justifyContent="space-between"
                        direction="row"
                        spacing={1}
                      >
                        <Typography variant="h5">To Be Delivered:</Typography>
                        <Stack direction="row" spacing={1}>
                          {cart.toBeDelivered ? (
                            <>
                              <Icon color="success">check_circle</Icon>
                              <Typography color="success">
                                {cart.deliveryCost} $
                              </Typography>
                            </>
                          ) : (
                            <Icon color="error">cancel</Icon>
                          )}
                        </Stack>
                      </Stack>
                      {cart.hasProhibitedItems && (
                        <Stack
                          justifyContent="space-between"
                          direction="row"
                          spacing={1}
                        >
                          <Typography variant="h5">
                            Contain Prohibited Item:
                          </Typography>
                          <Stack direction="row" spacing={1}>
                            {cart.hasProhibitedItems ? (
                              <Icon color="success">check_circle</Icon>
                            ) : (
                              <Icon color="error">cancel</Icon>
                            )}
                          </Stack>
                        </Stack>
                      )}
                    </Stack>
                    <Divider flexItem></Divider>
                    <Stack>
                      {cart.hasUSItem && (
                        <Stack
                          direction="row"
                          spacing={1}
                          justifyContent="space-between"
                        >
                          <Typography variant="h5">USA shipping:</Typography>
                          <Typography variant="h5">
                            {cart.totalUSShipping || 0} $
                          </Typography>
                        </Stack>
                      )}
                      {cart.hasTRItem && (
                        <Stack
                          direction="row"
                          spacing={1}
                          justifyContent="space-between"
                        >
                          <Typography variant="h5">Turkey shipping:</Typography>
                          <Typography variant="h5">
                            {cart.totalTRShipping || 0} $
                          </Typography>
                        </Stack>
                      )}
                      {cart.hasEUItem && (
                        <Stack
                          direction="row"
                          spacing={1}
                          justifyContent="space-between"
                        >
                          <Typography variant="h5">Europe shipping:</Typography>
                          <Typography variant="h5">
                            {cart.totalEUShipping || 0} $
                          </Typography>
                        </Stack>
                      )}
                      {cart.hasAGItem && (
                        <Stack
                          direction="row"
                          spacing={1}
                          justifyContent="space-between"
                        >
                          <Typography variant="h5">
                            Arabic Gulf shipping:
                          </Typography>
                          <Typography variant="h5">
                            {cart.totalAGShipping || 0} $
                          </Typography>
                        </Stack>
                      )}
                      {cart.hasWSMItem && (
                        <Stack
                          direction="row"
                          spacing={1}
                          justifyContent="space-between"
                        >
                          <Typography variant="h5">WSM shipping:</Typography>
                          <Typography variant="h5">
                            {cart.totalWSMShipping || 0} $
                          </Typography>
                        </Stack>
                      )}
                    </Stack>
                  </Stack>
                  <Divider flexItem></Divider>
                  <Stack spacing={2}>
                    <Box>
                      <Stack direction={"row"} spacing={1} marginLeft={0.5}>
                        <Tooltip title="Customer name">
                          <Icon>account_circle</Icon>
                        </Tooltip>
                        <Typography variant="body1">
                          {cart.customer ? cart.customer.fullName : ""}{" "}
                          <UserTierMenu
                            userId={cart?.customer?.id}
                            tier={cart?.customer?.customerTier}
                            onSuccess={() => setRefresh(!refresh)}
                          />
                        </Typography>
                      </Stack>
                      <Stack direction={"row"} spacing={1} marginLeft={0.5}>
                        <Tooltip title="Customer phone number">
                          <Icon>call</Icon>
                        </Tooltip>

                        <Typography variant="body1">
                          {cart.customer ? cart.customer.phoneNumber : ""}
                        </Typography>
                      </Stack>
                      {cart.customer?.address ? (
                        <Stack direction={"row"} spacing={1} marginLeft={0.5}>
                          <Tooltip title="Customer address">
                            <Icon>pin_drop</Icon>
                          </Tooltip>

                          <Typography variant="body1">
                            {cart.customer ? cart.customer.address : ""}
                          </Typography>
                        </Stack>
                      ) : null}
                      <Stack direction={"row"} spacing={1} alignItems="center">
                        <Tooltip title="Customer wallet">
                          <IconButton
                            size="small"
                            color="primary"
                            href={`/app/wallet/${
                              cart.customer ? cart.customer.id : ""
                            }`}
                          >
                            <Icon>account_balance_wallet</Icon>
                          </IconButton>
                        </Tooltip>

                        <Typography variant="body1">
                          {cart.balance !== undefined
                            ? cart.balance + "$"
                            : "0"}
                        </Typography>
                      </Stack>
                    </Box>
                  </Stack>
                  <Divider flexItem></Divider>
                  <Stack spacing={2}>
                    <Typography variant="h4">Cart review</Typography>
                    <Box>
                      {cart.needReviewAt ? (
                        <Tooltip title="Request review time">
                          <Stack direction={"row"} spacing={1}>
                            <Icon>rate_review</Icon>
                            <Typography variant="body1">
                              {cart.needReviewAt
                                ? moment(cart.needReviewAt).format(
                                    "yyy-MM-DD / hh:mm a"
                                  )
                                : ""}
                            </Typography>
                          </Stack>
                        </Tooltip>
                      ) : null}
                      <Tooltip title="Reviewer name">
                        <Stack direction={"row"} spacing={1}>
                          <Icon>assignment_ind</Icon>
                          <Typography variant="body1">
                            {cart.reviewer}
                          </Typography>
                        </Stack>
                      </Tooltip>
                      <Tooltip title="Review time">
                        <Stack direction={"row"} spacing={1}>
                          <Icon>event_available</Icon>
                          <Typography variant="body1">
                            {cart.reviewAt
                              ? moment(cart.reviewAt).format(
                                  "yyy-MM-DD / hh:mm a"
                                )
                              : ""}
                          </Typography>
                        </Stack>
                      </Tooltip>
                    </Box>
                  </Stack>
                </Stack>
              </Box>
            </Grid>
          </Grid>
        </Box>
      )}
    </Card>
  );
}
